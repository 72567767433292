import './ResetPasswordBox.css'
import {useRef, useState, useEffect} from "react";
import axios, {Axios, AxiosError} from "axios";
import {Link} from "react-router-dom";


function ResetPasswordBox() {
    const emailInputWrapperRef = useRef();
    const emailInputRef = useRef();
    const [passwordInputErrorInfo, setPasswordInputErrorInfo] = useState('');
    const [emailInputErrorInfo, setEmailInputErrorInfo] = useState('');
    const [resetPasswordStep, setResetPasswordStep] = useState(1);
    const tokenInputRef = useRef();
    const [tokenInputErrorInfo, setTokenInputErrorInfo] = useState('');
    const passwordInputRef = useRef();
    const passwordConfirmationInputRef = useRef();
    const [passwordConfirmationInputErrorInfo, setPasswordConfirmationInputErrorInfo] = useState('');
    const formRef = useRef();
    const tokenInputWrapperRef = useRef();
    const passwordInputWrapperRef = useRef();
    const passwordConfirmationInputWrapperRef = useRef();
    useEffect(() => {
        if(resetPasswordStep === 1){
            emailInputWrapperRef.current.style.display = "initial";
            tokenInputWrapperRef.current.style.display = "none";
            passwordInputWrapperRef.current.style.display = "none";
            passwordConfirmationInputWrapperRef.current.style.display = "none";
        }
        else if(resetPasswordStep === 2){
            emailInputWrapperRef.current.style.display = "none";
            tokenInputWrapperRef.current.style.display = "initial";
            passwordInputWrapperRef.current.style.display = "initial";
            passwordConfirmationInputWrapperRef.current.style.display = "initial";
        }
    }, [resetPasswordStep]);

    const getEmailInputValue = (event) => {
        emailInputRef.current.className = '';
        setEmailInputErrorInfo("");
        emailInputRef.current.value = event.target.value;
    }
    const getTokenInputValue = (event) => {
        tokenInputRef.current.className = '';
        setTokenInputErrorInfo("");
        tokenInputRef.current.value = event.target.value;
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const getPasswordInputValue = (event) => {
        passwordInputRef.current.className = '';
        setPasswordInputErrorInfo("");
        passwordInputRef.current.value = event.target.value;
    }
    const getPasswordConfirmationInputValue = (event) => {
        passwordConfirmationInputRef.current.className = '';
        setPasswordConfirmationInputErrorInfo("");
        passwordConfirmationInputRef.current.value = event.target.value;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
    //     const formData = {
    //         email: emailInputRef.current.value,
    //     };
    //     if (!validateEmail(formData.email)) {
    //         emailInputRef.current.className = 'error';
    //         setEmailInputErrorInfo("Gościu, to nawet mail nie jest..");
    //         return;
    //     }
    //     axios
    //         .post('http://localhost:3001/login', loginData, {
    //             withCredentials: true
    //         })
    //         .then((response) => {
    //             window.location.reload(false);
    //         })
    //         .catch((error) => {
    //
    //
    //             const errorInfo = error?.response?.data;
    //
    //             if (errorInfo?.code === -1) {
    //                 setEmailInputErrorInfo(errorInfo.info)
    //                 emailInputRef.current.className = 'error';
    //             } else if (errorInfo?.code === 0) {
    //                 setPasswordInputErrorInfo(errorInfo.info)
    //                 passwordInputRef.current.className = 'error';
    //             }
    //             else{
    //                 alert(error)
    //             }
    //
    //
    //         });
     }

    return (
        <div className={"reset-password-container"}>
            <div className={"reset-password"}>
                <div className={"tobenamed"}>
                    {
                        resetPasswordStep === 1 &&
                        <>
                            <h1>Resetowanie hasła</h1>
                        </>
                    }
                    {
                        resetPasswordStep === 2 &&
                        <>
                            <button className={"reset-password_back-button"} onClick={() => {
                                setResetPasswordStep(1);
                            }}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     id="back-arrow">
                                    <path fill="none" d="M0 0h24v24H0V0z" opacity=".87"></path>
                                    <path
                                        d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"></path>
                                </svg>
                            </button>
                            <h1 className={"transformedh1"}>Resetowanie hasła </h1>
                        </>
                    }
                </div>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <div ref={emailInputWrapperRef} className={'reset-password__input-wrapper'}>
                        <input onChange={getEmailInputValue} ref={emailInputRef} type="text"
                               placeholder={"E-mail"}/>
                        <label>E-mail</label>
                        <p className={'input-error-info'}>{emailInputErrorInfo}</p>
                    </div>
                    <div ref={tokenInputWrapperRef} className={'reset-password__input-wrapper'}>
                        <input ref={tokenInputRef} onChange={getTokenInputValue} type="text"
                               placeholder={"Token autoryzacyjny"}/>
                        <label>Token autoryzacyjny</label>
                        <p className={'input-error-info'}>{tokenInputErrorInfo}</p>
                    </div>
                    <div ref={passwordInputWrapperRef} className={'reset-password__input-wrapper'}>
                            <input onChange={getPasswordInputValue} ref={passwordInputRef} type="password"
                                   placeholder={"Nowe hasło"}/>
                            <label>Nowe hasło</label>
                            <p className={'input-error-info'}>{passwordInputErrorInfo}</p>
                    </div>
                    <div ref={passwordConfirmationInputWrapperRef} className={'reset-password__input-wrapper'}>
                            <input onChange={getPasswordConfirmationInputValue} ref={passwordConfirmationInputRef}
                                   type="password"
                                   placeholder={"Potwierdź nowe hasło"}/>
                            <label>Potwierdź nowe hasło</label>
                            <p className={'input-error-info'}>{passwordInputErrorInfo}</p>
                    </div>

                    <div className={'reset-password__input-wrapper'}>
                        <button onClick={() => {
                            if (validateEmail(emailInputRef.current.value)) {
                                setResetPasswordStep(2);
                                console.log(resetPasswordStep);
                            } else {
                                emailInputRef.current.className = 'error';
                                setEmailInputErrorInfo("Gościu, to nawet mail nie jest..");
                            }
                        }}>{resetPasswordStep===2 ? "Resetuj hasło" : "Dalej"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ResetPasswordBox;
