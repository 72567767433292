import './App.css';
import Nav from "./Components/Nav/Nav.js";
import {useEffect, useState} from "react";
import {BrowserRouter, Routes, Route, MemoryRouter, Link} from "react-router-dom";
import axios from "axios";
import MyCourses from "./Components/MyCourses/MyCourses";
import CourseView from "./Components/CourseView/CourseView";
import SignupLoginView from "./Components/SignupLoginView/SignupLoginView";
import ResetPasswordBox from "./Components/ResetPasswordBox/ResetPasswordBox";

function App() {
    const [userBasicData, setUserBasicData] = useState(false);
    useEffect(() => {
        axios
            .post('https://mudlock.tech/isLoggedIn', {}, {
                withCredentials: true
            })
            .then((response) => {
                const data = {
                    userId: response.data.userId,
                    firstName: response.data.userFirstName,
                    plan:response.data.userPlan
                }
                console.log(data);
                setUserBasicData(data);
            })
            .catch(() => {
                setUserBasicData(false);
            });
    }, []);

    return (
            <MemoryRouter>
                <Nav userBasicData={userBasicData}></Nav>
                <main>
                    <Routes>
                        <Route path={'/'}/>
                        <Route path={'/login'} element={<SignupLoginView option={"login"}/>}/>
                        <Route path={'/signup'} element={<SignupLoginView option={"signup"}/>}/>
                        {userBasicData &&
                            <Route path={'/my-courses'} element={<MyCourses userBasicData={userBasicData}/>}/>
                        }
                        <Route path={'/my-courses/:courseId'} element={<CourseView/>}/>
                        <Route path={'/reset-password'} element={<ResetPasswordBox/>}/>
                    </Routes>
                </main>
            </MemoryRouter>

    );
}

export default App;
