// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader
{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height:100px;
    padding:20px;
    border-radius:1px;
}
.loader--small
{
    width: 50px;
    height:50px;
}

.loader--large
{
    width: 200px;
    height:200px;
}


.rect {
    stroke-linecap:round;
    fill: none;
    stroke-width: 5;
    stroke: black;
    stroke-dasharray: 145;
    stroke-dashoffset: 144.99%;
    animation: movedash 0.9s forwards 0s infinite;
}
@keyframes movedash {
    0%
    {stroke-dashoffset: 144.99%;

    }
    100% {
        stroke-dashoffset: 338.31%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Loader/Loader.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,OAAO;IACP,QAAQ;IACR,8BAA8B;IAC9B,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;AACA;;IAEI,WAAW;IACX,WAAW;AACf;;AAEA;;IAEI,YAAY;IACZ,YAAY;AAChB;;;AAGA;IACI,oBAAoB;IACpB,UAAU;IACV,eAAe;IACf,aAAa;IACb,qBAAqB;IACrB,0BAA0B;IAC1B,6CAA6C;AACjD;AACA;IACI;KACC,0BAA0B;;IAE3B;IACA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".loader\n{\n    position:absolute;\n    top:50%;\n    left:50%;\n    transform:translate(-50%,-50%);\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    width: 100px;\n    height:100px;\n    padding:20px;\n    border-radius:1px;\n}\n.loader--small\n{\n    width: 50px;\n    height:50px;\n}\n\n.loader--large\n{\n    width: 200px;\n    height:200px;\n}\n\n\n.rect {\n    stroke-linecap:round;\n    fill: none;\n    stroke-width: 5;\n    stroke: black;\n    stroke-dasharray: 145;\n    stroke-dashoffset: 144.99%;\n    animation: movedash 0.9s forwards 0s infinite;\n}\n@keyframes movedash {\n    0%\n    {stroke-dashoffset: 144.99%;\n\n    }\n    100% {\n        stroke-dashoffset: 338.31%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
