import './SignupLoginView.css'
import {Link} from "react-router-dom";
import LoginBox from "../LoginBox/LoginBox";
import {useEffect, useState} from "react";
import SignUpBox from "../SignUpBox/SignUpBox";

function SignupLoginView({option}) {
    const [view, setView] = useState(option);

    useEffect(() => {
        setView(option)
    }, [option]);
    return (
        <>
            <div className={"view-switcher-wrapper"}>
                <div className={"view-switcher"}>
                    <div
                        className={`view-switcher__picker ${view === "login" ? "view-switcher__picker--left" : "view-switcher__picker--right"}`}></div>
                    <Link className={"view-switcher__item"} to={"/login"}>Logowanie</Link>
                    <Link className={"view-switcher__item"} to={"/signup"}>Rejestracja</Link>
                </div>
            </div>
            <div className="mcontainer">
                {view === "login" ? <LoginBox/> : <SignUpBox/>}
            </div>
        </>

    );
}

export default SignupLoginView;
