import './LoginBox.css'
import {useRef, useState} from "react";
import axios, {Axios, AxiosError} from "axios";
import {Link} from "react-router-dom";


function LoginBox() {
    const [passwordInputErrorInfo, setPasswordInputErrorInfo] = useState('');
    const [emailInputErrorInfo, setEmailInputErrorInfo] = useState('');

    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const formRef = useRef();

    const getEmailInputValue = (event) => {
        emailInputRef.current.className = '';
        setEmailInputErrorInfo("");
        emailInputRef.current.value = event.target.value;
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const getPasswordInputValue = (event) => {
        passwordInputRef.current.className = '';
        setPasswordInputErrorInfo("");
        passwordInputRef.current.value = event.target.value;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const loginData = {
            email: emailInputRef.current.value,
            password: passwordInputRef.current.value
        };
        if (!validateEmail(loginData.email)) {
            emailInputRef.current.className = 'error';
            setEmailInputErrorInfo("Gościu, to nawet mail nie jest..");
            return;
        }
        axios
            .post('https://mudlock.tech/login', loginData, {
                withCredentials: true
            })
            .then((response) => {
                window.location.reload(false);
            })
            .catch((error) => {


                const errorInfo = error?.response?.data;

                if (errorInfo?.code === -1) {
                    setEmailInputErrorInfo(errorInfo.info)
                    emailInputRef.current.className = 'error';
                } else if (errorInfo?.code === 0) {
                    setPasswordInputErrorInfo(errorInfo.info)
                    passwordInputRef.current.className = 'error';
                }
                else{
                    alert(error)
                }


            });
    }

    return (
            <div className={"login-box"}>
                <h1>Logowanie</h1>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <div className={'login-box__input-wrapper'}>
                        <input onChange={getEmailInputValue} ref={emailInputRef} type="text"
                               placeholder={"E-mail"}/>
                        <label>E-mail</label>
                        <p className={'input-error-info'}>{emailInputErrorInfo}</p>
                    </div>
                    <div className={'login-box__input-wrapper'}>
                        <input onChange={getPasswordInputValue} ref={passwordInputRef} type="password"
                               placeholder={"Hasło"}/>
                        <label>Hasło</label>
                        <p className={'input-error-info'}>{passwordInputErrorInfo}</p>
                    </div>
                    <Link to={"/reset-password"} className="password-forgotten-link" >Nie pamiętasz hasła?</Link>
                    <div className={'login-box__input-wrapper'}>
                        <button type={"submit"}>Zaloguj</button>
                    </div>
                    {/*<Link to={"/signup"}>Nie mam konta</Link>*/}
                </form>
            </div>
    );
}

export default LoginBox;
