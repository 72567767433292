// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-courses-container{
    display: flex;
    width:100%;
    height:calc(100dvh - 100px);
    padding: 10px;
    background-color: var(--subtle-blue);
    margin: 0 auto;
    position:relative;
}
.my-courses
{
    display: grid;
    justify-items: center;
    align-content: flex-start;
    width:100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 25px;
    overflow:auto;
}

@media screen and (min-width: 500px){
    .my-courses-container{
        max-width:1900px;
        width:calc(100% - 20px);
        margin:0 auto;
        border-radius:15px;
    }
}

@media screen and (min-width: 700px){
    .my-courses{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1200px){
    .my-courses{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (min-width: 1600px){
    .my-courses{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/MyCourses/MyCourses.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,2BAA2B;IAC3B,aAAa;IACb,oCAAoC;IACpC,cAAc;IACd,iBAAiB;AACrB;AACA;;IAEI,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,UAAU;IACV,0BAA0B;IAC1B,wBAAwB;IACxB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,gBAAgB;QAChB,uBAAuB;QACvB,aAAa;QACb,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,kCAAkC;IACtC;AACJ;AACA;IACI;QACI,sCAAsC;IAC1C;AACJ","sourcesContent":[".my-courses-container{\n    display: flex;\n    width:100%;\n    height:calc(100dvh - 100px);\n    padding: 10px;\n    background-color: var(--subtle-blue);\n    margin: 0 auto;\n    position:relative;\n}\n.my-courses\n{\n    display: grid;\n    justify-items: center;\n    align-content: flex-start;\n    width:100%;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n    grid-row-gap: 25px;\n    overflow:auto;\n}\n\n@media screen and (min-width: 500px){\n    .my-courses-container{\n        max-width:1900px;\n        width:calc(100% - 20px);\n        margin:0 auto;\n        border-radius:15px;\n    }\n}\n\n@media screen and (min-width: 700px){\n    .my-courses{\n        grid-template-columns: 1fr 1fr;\n    }\n}\n\n@media screen and (min-width: 1200px){\n    .my-courses{\n        grid-template-columns: 1fr 1fr 1fr;\n    }\n}\n@media screen and (min-width: 1600px){\n    .my-courses{\n        grid-template-columns: 1fr 1fr 1fr 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
