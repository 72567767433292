import './UserAvatar.css'

function UserAvatar({userData, size}) {
    return(
            <div className={"user-avatar"}>
            <div
                style={{backgroundImage: `url(https://mudlock.tech/resources/${userData.plan}.avif)`}}
                className={`user-avatar__ring ${size?"user-avatar__ring--"+size:""}`}>
                <div
                    style={{backgroundImage: `url(https://mudlock.tech/userAvatars/${userData.userId}.png)`}}
                    className={`user-avatar__ring__picture ${size?"user-avatar__ring__picture--"+size:""}`}>
                </div>
            </div>
        </div>

    )
}

export default UserAvatar;
