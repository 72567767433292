import './CourseChapter.css'
import {digits} from '../../assets/icons'
import {useEffect, useRef, useState} from "react";


function CourseChapter({chapterData, activeChapter, activateChapter}) {
    const [courseChapterCollapsed, setCourseChapterCollapsed] = useState('');
    useEffect(() => {
        toggleCourseChapterCollapseState();
    }, [activeChapter]);
    const toggleCourseChapterCollapseState = () => {
        if (activeChapter === chapterData.index) {
            setCourseChapterCollapsed('course-chapter-content--collapsed');
        } else {
            setCourseChapterCollapsed('');
        }
    }
    console.log(chapterData)
    return (
        <div className={"course-chapter-container"} onClick={activateChapter}>
            <div className={`course-chapter-content ${courseChapterCollapsed}`}>
                <div className={"course-chapter-content__number"}>
                    <img src={`${digits[chapterData.index]}`} alt=""/>
                </div>
                <div
                    className={"course-chapter-content__name"}>{chapterData.name.substring(chapterData.name.indexOf('.') + 1)}
                </div>
            </div>
            <flex-break></flex-break>
            <div
                className={`course-chapter-dropdown-wrapper ${courseChapterCollapsed ? "course-chapter__content-wrapper--collapsed" : ""}`}>
                <div className={`course-chapter-dropdown__content`}>
                    <ol>


                        {
                            chapterData.subchapters ? chapterData.subchapters.map((subchapter) => {
                                return (
                                    <>
                                        <li><a href={`#${subchapter.href}`} className={"course-chapter-dropdown__content__subchapter"}>{subchapter.name}</a>
                                            <ol>
                                                {
                                                    subchapter.subchapters ? subchapter.subchapters.map((subsubchapter) => {
                                                        return <li><a href={`#${subsubchapter.href}`} className={"course-chapter-dropdown__content__subsubchapter"} >{subsubchapter.name}</a></li>
                                                    }) : null
                                                }
                                            </ol>
                                        </li>
                                    </>
                                );

                            }) : null
                        }
                    </ol>
                </div>
            </div>
        </div>
    )


}

export default CourseChapter;