import './CourseTableOfContent.css'
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import CourseChapter from "../CourseChapter/CourseChapter";




function CourseTableOfContent({courseId, setCourseChapter, visible}) {
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            await getTableOfContentWithProgressData(courseId);
        })()
    }, []);

    const [tableOfContentData, setTableOfContentData] = useState(false);
    const [activeChapter, setActiveChapter] = useState(0);

    useEffect(() => {
        setCourseChapter(activeChapter+1);
    }, [activeChapter]);
    const getTableOfContentWithProgressData = async (courseId) => {

        axios
            .post(`https://mudlock.tech/course-info`, {ids: courseId}, {
                withCredentials: true
            })
            .then(({data}) => {
                setTableOfContentData(data[0].courseChapters);
                console.log(data[0].courseChapters, tableOfContentData);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <div className={`course-tableOfContent-container ${visible?"":"course-tableOfContent-container--hidden"}`}>
            {tableOfContentData&&

                    tableOfContentData.map((chapter, index) => {
                        return <CourseChapter activeChapter={activeChapter} activateChapter={()=>{setActiveChapter(index)}} chapterData={{...chapter, index}}/>
                    })
            }
        </div>
    )
}

export default CourseTableOfContent;