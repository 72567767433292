export const profileIconEmpty = require('./profile-icon-empty.svg').default;

export const tableOfContentIcons = {
    open: require('./table-of-content-switch.svg').default,
    close: require('./triangle-down.svg').default,
}
export const digits = [
    require('./1-digit.svg').default,
    require('./2-digit.svg').default,

]