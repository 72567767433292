import './SignUpBox.css'
import {useRef, useState, useId} from "react";
import axios from "axios";
import {Link} from "react-router-dom";

function SignUpBox() {
    const id = useId();
    const [passwordInputErrorInfo, setPasswordInputErrorInfo] = useState('');
    const [emailInputErrorInfo, setEmailInputErrorInfo] = useState('');
    const [firstNameInputErrorInfo, setFirstNameInputErrorInfo] = useState('');
    const [birthdateInputErrorInfo, setBirthdateInputErrorInfo] = useState('');
    const [confirmPasswordInputErrorInfo, setConfirmPasswordInputErrorInfo] = useState('');

    const formRef = useRef();
    const inputRefs = {
        firstName: useRef(),
        birthDate: useRef(),
        email: useRef(),
        password: useRef(),
        confirmPassword: useRef(),
        termsAccepted: useRef(),
    }

    const getInsertedInInputValue = (event, ref, inputErrorSetter) => {
        event.target.className = '';
        if (inputErrorSetter) inputErrorSetter("");
        ref.current.value = event.target.value;
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const validatePassword = (password) => {
        console.log(password)
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\-\/\\]).*$/;
        if (password.length < 8) {
            return {
                valid: false,
                message: "Hasło powinno mieć min. 8 znaków."
            }
        } else if (password.length > 256) {
            return {
                valid: false,
                message: "No bez przesady..."
            }
        } else if (!regex.test(password)) {

            return {
                valid: false,
                message: "Hasło nie spełnia wymagań złożoności"
            }
        } else
            return {
                valid: true,
            }

    };
    const handleSubmit = (event) => {
        event.preventDefault();
        let errorState = false;
        const loginData = {
            firstName: inputRefs.firstName.current.value,
            birthDate: inputRefs.birthDate.current.value,
            email: inputRefs.email.current.value,
            password: inputRefs.password.current.value
        };
        if (!loginData.firstName.length) {
            inputRefs.firstName.current.className = 'error';
            setFirstNameInputErrorInfo("Podaj imię")
            errorState = true;
        }
        if (!loginData.birthDate.length) {
            inputRefs.birthDate.current.className = 'error';
            setBirthdateInputErrorInfo("Podaj datę urodzenia")
            errorState = true;
        }
        if (!validateEmail(loginData.email)) {
            inputRefs.email.current.className = 'error';
            setEmailInputErrorInfo("To nie wygląda jak adres e-mail.");
            errorState = true;
        }

        const validation = validatePassword(loginData.password);
        if (!validation.valid) {
            inputRefs.password.current.className = 'error';
            setPasswordInputErrorInfo(validation.message);
            errorState = true;
        }
        if (loginData.password !== inputRefs.confirmPassword.current.value) {
            inputRefs.confirmPassword.current.className = 'error';
            setConfirmPasswordInputErrorInfo("Hasła nie są takie same.")
            errorState = true;
        }
        console.log(inputRefs.termsAccepted)
        if (!inputRefs.termsAccepted.current.checked) {
            inputRefs.termsAccepted.current.className = 'error';
            errorState = true;
        }

        if (!errorState) {
            axios
                .post('https://mudlock.tech/register', loginData, {
                    withCredentials: true
                })
                .then((response) => {
                    window.location.reload(false);
                })
                .catch((error) => {
                    const errorInfo = error.response.data;
                    if (errorInfo.code === -1) {
                        setEmailInputErrorInfo(errorInfo.info)
                        inputRefs.email.current.className = 'error';
                    } else if (errorInfo.code === 0) {
                        setPasswordInputErrorInfo(errorInfo.info)
                        inputRefs.password.current.className = 'error';
                    }
                });
        }
    }

    return (
            <div className={"signup-box"}>
                <h1>Rejestracja</h1>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <div className={"signup-box__userData-inputs-wrapper"}>
                        <div className={'signup-box__input-wrapper'}>
                            <input onChange={(event) => {
                                getInsertedInInputValue(event, inputRefs.firstName, setFirstNameInputErrorInfo)
                            }} id={id + '-firstName'} ref={inputRefs.firstName}
                                   type="text"
                                   placeholder={"Imię"}/>
                            <label htmlFor={id + '-firstName'}>Imię</label>
                            <p className={'input-error-info'}>{firstNameInputErrorInfo}</p>
                        </div>
                        <div className={'signup-box__input-wrapper'}>
                            <input onChange={(event) => {
                                getInsertedInInputValue(event, inputRefs.birthDate, setBirthdateInputErrorInfo)
                            }} id={id + '-birthDate'} ref={inputRefs.birthDate}
                                   type="date"
                                   placeholder={"Urodziny"}/>
                            <label htmlFor={id + '-firstName'}>Urodziny</label>
                            <p className={'input-error-info'}>{birthdateInputErrorInfo}</p>
                        </div>
                    </div>

                    <div className={'signup-box__input-wrapper'}>
                        <input onChange={(event) => {
                            getInsertedInInputValue(event, inputRefs.email, setEmailInputErrorInfo)
                        }} ref={inputRefs.email} id={id + '-email'} type="text"
                               placeholder={"E-mail"}/>
                        <label htmlFor={id + '-email'}>E-mail</label>
                        <p className={'input-error-info'}>{emailInputErrorInfo}</p>
                    </div>
                    <div className={'signup-box__input-wrapper'}>
                        <input onChange={(event) => {
                            getInsertedInInputValue(event, inputRefs.password, setPasswordInputErrorInfo)
                        }} ref={inputRefs.password} type="password"
                               placeholder={"Hasło"}/>
                        <label htmlFor={id + '-password'}>Hasło</label>
                        <p className={'input-error-info'}>{passwordInputErrorInfo}</p>
                    </div>
                    <div className={'signup-box__input-wrapper'}>
                        <input onChange={(event) => {
                            getInsertedInInputValue(event, inputRefs.confirmPassword, setConfirmPasswordInputErrorInfo)
                        }} ref={inputRefs.confirmPassword} type="password"
                               placeholder={"Potwierdź hasło"}/>
                        <label htmlFor={id + '-password-confirm'}>Potwierdź hasło</label>
                        <p className={'input-error-info'}>{confirmPasswordInputErrorInfo}</p>
                    </div>
                    <div className={'signup-box__input-wrapper'}>
                        <input onChange={(event) => {
                            getInsertedInInputValue(event, inputRefs.termsAccepted)
                        }} ref={inputRefs.termsAccepted} type="checkbox"/>
                        <span>Akceptuję regulamin serwisu</span>
                    </div>

                    <div className={'signup-box__input-wrapper'}>
                        <button type={"submit"}>Utwórz konto</button>
                    </div>

                    {/*<Link to={"/login"}>Mam już konto</Link>*/}
                </form>
            </div>
    );
}

export default SignUpBox;
