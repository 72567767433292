import './CourseContent.css'
import CourseCover from "../CourseCover/CourseCover";
import Loader from "../Loader/Loader";
import { useInView } from 'react-intersection-observer';
import {useEffect, useLayoutEffect, useRef} from "react";
function CourseContent({content, expanded}) {

    useLayoutEffect(() => {
        courseContentContainer.current.innerHTML = content;
        const observer = new IntersectionObserver(function(entries) {
            entries.forEach((entry)=>{
                if(entry.isIntersecting && entry.intersectionRatio > 0)
                {
                    document.querySelectorAll('a[href^="#"]').forEach((elem)=>{elem.classList.remove("course-chapter-dropdown__content__subchapter--active")});
                    document.querySelector(`a[href="#${entry.target.id}"]`).classList.add('course-chapter-dropdown__content__subchapter--active');

                }
            })
        }, {root:courseContentContainer.current, rootMargin: "-50% 0px -49% 0px",});

        courseContentContainer.current.querySelectorAll('[id]').forEach((elem)=>{
            observer.observe(elem);
        });
        return ()=>{
            courseContentContainer.current.querySelectorAll('[id]').forEach((elem)=>{
                console.log(`Odobserwowano ${elem}`)
                document.querySelectorAll('a[href^="#"]').forEach((elem)=>{elem.classList.remove("course-chapter-dropdown__content__subchapter--active")});
                observer.unobserve(elem);
            });
        }
    }, [content]);
const courseContentContainer = useRef();
    return (
        <div ref={courseContentContainer} className={`course-content-container ${expanded?"":"course-content-container--expanded"}`}>

        </div>
    )
}

export default CourseContent;