import './UserCard.css'
import UserAvatar from "../UserAvatar/UserAvatar.js";
import {Link} from "react-router-dom";
import axios from "axios";


function UserCard({visible, userData}) {
    const logoutUser = () =>{
        axios.post('https://mudlock.tech/logout',{},{
            withCredentials:true}
        ).then(()=>{window.location.reload()})
            .catch((err)=>{console.log(err)});
    }
    let state = visible ? "user-card--visible" : "user-card--hidden";
    return (
        <div className={`user-card ${state}`}>
            <div className={"user-card__greeting"}>
                <UserAvatar userData={userData}
                />

                <span className={`user-card__greeting__plan--${userData.plan}`}>
                    {userData.plan.at(0).toUpperCase() + userData.plan.slice(1)}
                </span>
                <h1>
                    Dzień dobry,<br/> {`${userData.firstName}`}
                </h1>
            </div>
            <div className={"user-card__nav"}>
                <Link to={'/my-courses'}>Moje kursy</Link>
                <Link>Ustawienia konta</Link>
                <Link onClick={logoutUser}>Wyloguj</Link>
            </div>

        </div>
    )
}

export default UserCard;
