// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 500px){
    .user-avatar{
        margin-top:-20px;
    }
}
.user-avatar__ring{
    position: relative;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size:contain;
    display: flex;
    justify-content: center;
    width:130px;
    height:175px;
}
.user-avatar__ring__picture{
    background-position: center;
    background-size:cover;
    position:absolute;
    bottom:10px;
    width:110px;
    height:110px;
    border-radius:100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}
.user-avatar__ring__picture--small{
    bottom:5px;
    width:35px;
    height:35px;
}

.user-avatar__ring--small{
    width:45px;
    height:65px;
}

.user-avatar__plan-name{
    text-align: center;
    padding:5px;
}
.user-avatar__plan-name--premium{
color:purple;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserAvatar/UserAvatar.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,kCAAkC;IAClC,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,YAAY;AAChB;AACA;IACI,2BAA2B;IAC3B,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,0CAA0C;AAC9C;AACA;IACI,UAAU;IACV,UAAU;IACV,WAAW;AACf;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;AACA,YAAY;AACZ","sourcesContent":["@media screen and (max-width: 500px){\n    .user-avatar{\n        margin-top:-20px;\n    }\n}\n.user-avatar__ring{\n    position: relative;\n    background-repeat: no-repeat;\n    background-position: center bottom;\n    background-size:contain;\n    display: flex;\n    justify-content: center;\n    width:130px;\n    height:175px;\n}\n.user-avatar__ring__picture{\n    background-position: center;\n    background-size:cover;\n    position:absolute;\n    bottom:10px;\n    width:110px;\n    height:110px;\n    border-radius:100%;\n    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);\n}\n.user-avatar__ring__picture--small{\n    bottom:5px;\n    width:35px;\n    height:35px;\n}\n\n.user-avatar__ring--small{\n    width:45px;\n    height:65px;\n}\n\n.user-avatar__plan-name{\n    text-align: center;\n    padding:5px;\n}\n.user-avatar__plan-name--premium{\ncolor:purple;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
