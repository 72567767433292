// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    font-family: "Nunito", sans-serif;
}

/* Chrome, Edge and Safari */
@media screen and (min-width:500px)
{
    *::-webkit-scrollbar {
        position:absolute;
        height: 27px;
        width: 27px;
    }
    *::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: inset 0 0 10px 10px rgb(234,236,248);
        border: solid 10px transparent;


    }

    *::-webkit-scrollbar-track:hover {
        background-color: transparent;
    }

    *::-webkit-scrollbar-track:active {
        background-color: transparent;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: transparent;
        box-shadow: inset 0 0 10px 10px rgb(211,218,249);
        border: solid 10px transparent;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: transparent;
        box-shadow: inset 0 0 10px 10px rgb(167,180,243);
    }

    *::-webkit-scrollbar-thumb:active {
        background-color: transparent;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;IACI,iCAAiC;AACrC;;AAEA,4BAA4B;AAC5B;;IAEI;QACI,iBAAiB;QACjB,YAAY;QACZ,WAAW;IACf;IACA;QACI,mBAAmB;QACnB,6BAA6B;QAC7B,gBAAgB;QAChB,mBAAmB;QACnB,gDAAgD;QAChD,8BAA8B;;;IAGlC;;IAEA;QACI,6BAA6B;IACjC;;IAEA;QACI,6BAA6B;IACjC;;IAEA;QACI,mBAAmB;QACnB,6BAA6B;QAC7B,gDAAgD;QAChD,8BAA8B;IAClC;;IAEA;QACI,6BAA6B;QAC7B,gDAAgD;IACpD;;IAEA;QACI,6BAA6B;IACjC;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');\nbody{\n    font-family: \"Nunito\", sans-serif;\n}\n\n/* Chrome, Edge and Safari */\n@media screen and (min-width:500px)\n{\n    *::-webkit-scrollbar {\n        position:absolute;\n        height: 27px;\n        width: 27px;\n    }\n    *::-webkit-scrollbar-track {\n        border-radius: 20px;\n        background-color: transparent;\n        margin-top: 20px;\n        margin-bottom: 20px;\n        box-shadow: inset 0 0 10px 10px rgb(234,236,248);\n        border: solid 10px transparent;\n\n\n    }\n\n    *::-webkit-scrollbar-track:hover {\n        background-color: transparent;\n    }\n\n    *::-webkit-scrollbar-track:active {\n        background-color: transparent;\n    }\n\n    *::-webkit-scrollbar-thumb {\n        border-radius: 20px;\n        background-color: transparent;\n        box-shadow: inset 0 0 10px 10px rgb(211,218,249);\n        border: solid 10px transparent;\n    }\n\n    *::-webkit-scrollbar-thumb:hover {\n        background-color: transparent;\n        box-shadow: inset 0 0 10px 10px rgb(167,180,243);\n    }\n\n    *::-webkit-scrollbar-thumb:active {\n        background-color: transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
