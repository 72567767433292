import './CourseCover.css'
import { useNavigate } from "react-router-dom";
function CourseCover({courseBasicInfo}) {
    console.log(courseBasicInfo);
    const navigate = useNavigate();
    return (
        <div onClick={()=>{console.log(`/my-courses/${courseBasicInfo._id}`); navigate(`/my-courses/${courseBasicInfo._id}`)}} className={"course-cover"}>
            <div className="course-cover__img">
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1822px-ISO_C%2B%2B_Logo.svg.png"
                    alt=""/>
            </div>
            <div className="course-cover__title">
                <span>{courseBasicInfo.courseTitle}</span>
            </div>
            <div className="course-cover__progress-bar">
                <progress max={"100"} value={courseBasicInfo.courseProgress}></progress>
                <span>{`${courseBasicInfo.courseProgress}%`}</span>
            </div>
        </div>
    )
}

export default CourseCover;