// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-tableOfContent-container{
    width:100%;
    height:100%;
    bottom:0;
    background-color: rgb(247,248,254);
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.05);
    transform:translate(0,0) scale(1);
    position:absolute;
    z-index:100;
    opacity:1;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition:all .5s ease;
    overflow:auto;
}
.course-tableOfContent-container--hidden{
    opacity:0;
    transform:translate(50%,50%) scale(0);
}

@media screen and (min-width: 500px){
    .course-tableOfContent-container{
        border-radius:15px;
    }
}

@media screen and (min-width: 1000px){
    .course-tableOfContent-container{
        position:relative;
        background-color: var(--subtle-blue);
        width:25%;
        border-radius:15px;
        transform:translateX(0);
        transition: all .5s ease;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1)
    }
    .course-tableOfContent-container--hidden{
        display:flex;
        transform:translateX(-150%);
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CourseTableOfContent/CourseTableOfContent.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,QAAQ;IACR,kCAAkC;IAClC,2BAA2B;IAC3B,4BAA4B;IAC5B,4CAA4C;IAC5C,iCAAiC;IACjC,iBAAiB;IACjB,WAAW;IACX,SAAS;IACT,YAAY;IACZ,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;AACA;IACI,SAAS;IACT,qCAAqC;AACzC;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,oCAAoC;QACpC,SAAS;QACT,kBAAkB;QAClB,uBAAuB;QACvB,wBAAwB;QACxB;IACJ;IACA;QACI,YAAY;QACZ,2BAA2B;IAC/B;AACJ","sourcesContent":[".course-tableOfContent-container{\n    width:100%;\n    height:100%;\n    bottom:0;\n    background-color: rgb(247,248,254);\n    border-top-left-radius:15px;\n    border-top-right-radius:15px;\n    box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.05);\n    transform:translate(0,0) scale(1);\n    position:absolute;\n    z-index:100;\n    opacity:1;\n    display:flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    transition:all .5s ease;\n    overflow:auto;\n}\n.course-tableOfContent-container--hidden{\n    opacity:0;\n    transform:translate(50%,50%) scale(0);\n}\n\n@media screen and (min-width: 500px){\n    .course-tableOfContent-container{\n        border-radius:15px;\n    }\n}\n\n@media screen and (min-width: 1000px){\n    .course-tableOfContent-container{\n        position:relative;\n        background-color: var(--subtle-blue);\n        width:25%;\n        border-radius:15px;\n        transform:translateX(0);\n        transition: all .5s ease;\n        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1)\n    }\n    .course-tableOfContent-container--hidden{\n        display:flex;\n        transform:translateX(-150%);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
