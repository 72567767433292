import './MyCourses.css'
import CourseCover from "../CourseCover/CourseCover";
import axios from "axios";
import {useEffect, useState} from "react";
import Loader from "../Loader/Loader";

function MyCourses({userBasicData}) {
    const [coursesInfo, setCoursesInfo] = useState(false);
    const {userId} = userBasicData;
    useEffect(() => {
        getCoursesInfo(userId).then((data) => {
            setCoursesInfo(data);
        });
    }, []);

    const getUserOwnedCoursesList = async (userId) => {
        return axios
            .post(`https://mudlock.tech/user-courses`, {id: userId}, {
                withCredentials: true
            })
            .then(({data}) => {
                return data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const getCoursesInfo = (userId) => {
        let coursesProgress = {};
        return getUserOwnedCoursesList(userId).then((courses) => {
            const coursesIds = [];
            for (const course of courses) {
                coursesIds.push(course.courseId);
                coursesProgress[course.courseId] = course.courseProgress;
            }
            return coursesIds;
        }).then((coursesIds) => {
            return axios
                .post(`https://mudlock.tech/course-info`, {ids: coursesIds}, {
                    withCredentials: true
                })
                .then(({data}) => {
                    data.forEach((elem)=>{
                        elem["courseProgress"]=coursesProgress[elem._id];
                    })
                    return data;
                })
                .catch((error) => {
                    console.log("Kursu nie ma", error);
                });
        })
    }

    return (
        <div className={"my-courses-container"}>
            {coursesInfo ?
                (
                    <div className="my-courses">
                        {coursesInfo.map((courseInfo) => {
                            return (
                                <CourseCover key={courseInfo["_id"]} courseBasicInfo={courseInfo} />
                            );
                        })}
                    </div>
                ):<Loader size={"large"}/>
            }
        </div>
    )
}

export default MyCourses;