// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-content-container{
    width:100%;
    background-color: var(--subtle-blue);
    padding:30px;
    position:relative;
    scroll-behavior: smooth;
    overflow:auto;
}
@media screen and (min-width: 500px){
    .course-content-container{
        border-radius:15px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    }
}
@media screen and (min-width: 1000px){
    .course-content-container{
        width:75%;
        border-radius:15px;
        transition: all 0.5s ease;
    }
    .course-content-container--expanded{
        width:100%;
        margin-left:-50%;
    }
}


`, "",{"version":3,"sources":["webpack://./src/Components/CourseContent/CourseContent.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oCAAoC;IACpC,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;AACjB;AACA;IACI;QACI,kBAAkB;QAClB,0CAA0C;IAC9C;AACJ;AACA;IACI;QACI,SAAS;QACT,kBAAkB;QAClB,yBAAyB;IAC7B;IACA;QACI,UAAU;QACV,gBAAgB;IACpB;AACJ","sourcesContent":[".course-content-container{\n    width:100%;\n    background-color: var(--subtle-blue);\n    padding:30px;\n    position:relative;\n    scroll-behavior: smooth;\n    overflow:auto;\n}\n@media screen and (min-width: 500px){\n    .course-content-container{\n        border-radius:15px;\n        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);\n    }\n}\n@media screen and (min-width: 1000px){\n    .course-content-container{\n        width:75%;\n        border-radius:15px;\n        transition: all 0.5s ease;\n    }\n    .course-content-container--expanded{\n        width:100%;\n        margin-left:-50%;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
