import './Nav.css';
import HamburgerMenuIcon from "../HamburgerMenuIcon/HamburgerMenuIcon";
import {logoMudlock} from "../../assets/images";
import {profileIconEmpty} from "../../assets/icons";
import {useRef, useState} from "react";
import {Link} from "react-router-dom";
import UserCard from "../UserCard/UserCard.js";
import UserAvatar from "../UserAvatar/UserAvatar.js";
import userCard from "../UserCard/UserCard.js";



function Nav({userBasicData}) {
    const userCardButtonRef = useRef();
    console.log(userBasicData)
    const {userId, firstName} = userBasicData;
    const [hamburgerMenuState, setHamburgerMenuState] = useState('nav-links--hidden');
    const [showUserCard, setShowUserCard] = useState(false);
    const toggleHamburgerMenu = () => {
        if (hamburgerMenuState === "nav-links--expanded") {
            setHamburgerMenuState("nav-links--hidden");
        } else {
            setShowUserCard(false);
            setHamburgerMenuState("nav-links--expanded");
        }
    }
    const toggleShowUserCard = (event) => {

        document.addEventListener('click', (e)=>{
            if(!(userCardButtonRef.current.contains(e.target))){
                setShowUserCard(false)
            }
        })

        if(showUserCard){
            setShowUserCard(false);
        }
        else {
            setHamburgerMenuState(false);
            setShowUserCard(true);
        }
        return () => {
            document.removeEventListener("click", () => {
            });
        };

    }
    return (
        <nav>
            <div className="container">
                <Link to={"/"} className="nav-logo">
                    <img src={logoMudlock} alt="Strona główna" aria-label={"Przejdź do strony głównej"}/>
                </Link>
                <div className={`nav-links ${hamburgerMenuState}`}>
                    <Link to="/my-courses">Moje kursy</Link>
                    <a href="">Sklep</a>
                    <a href="">O nas</a>
                    {
                        !userId &&
                        <Link className="nav-links__loginLink" to={'/login'}>
                            Logowanie
                        </Link>
                    }
                </div>
                <div className="nav-menu">
                    {
                        userId ?
                            <div ref={userCardButtonRef} className="nav-menu__profile">
                                <button  aria-label="Konto" onClick={toggleShowUserCard}>
                                    <UserAvatar userData={userBasicData}
                                                size={"small"}
                                    />
                                    <span>{firstName}</span>
                                </button>
                            </div>
                            :
                            <div className="nav-menu__profile nav-menu__profile--notLoggedIn">
                                <Link to={"/login"}>
                                    <button aria-label="Konto">
                                        <img className={"nav-menu__profile nav-menu__profile--login"}
                                             src={profileIconEmpty}
                                             alt="Avatar"/>
                                    </button>
                                </Link>
                            </div>
                    }
                    <div className={"nav-menu__hamburger"} onClick={toggleHamburgerMenu}>
                        <HamburgerMenuIcon state={hamburgerMenuState}/>
                    </div>
                </div>
            </div>
            {userBasicData && <UserCard visible={showUserCard} userData={userBasicData} />}


        </nav>
    );
}

export default Nav;