// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-cover{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width:90%;
    aspect-ratio: 1.5 / 1;
    background-color: var(--subtle-blue);
    border-radius: 15px;
}

.course-cover__img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.course-cover__img img{
    width:30%;
    height:auto;
}
.course-cover__title{
    text-align: center;
}
.course-cover__title span
{
    font-size:18px;

}
.course-cover__progress-bar{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width:75%;
}
.course-cover__progress-bar progress[value]{
    appearance: none;
    width:100%;
    height:15px;
}
.course-cover__progress-bar progress[value]::-webkit-progress-bar
{
    background-color: var(--subtle-blue);
    border:1px solid rgba(0,0,0,0.05);
    border-radius: 5px;
}

.course-cover__progress-bar progress[value]::-webkit-progress-value
{
    background-color: rgba(102, 126, 234, 0.5);
    border-radius: 5px;
}

.course-cover__progress-bar span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    font-size:12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/CourseCover/CourseCover.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;IAC7B,SAAS;IACT,qBAAqB;IACrB,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,SAAS;IACT,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;;IAEI,cAAc;;AAElB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,UAAU;IACV,WAAW;AACf;AACA;;IAEI,oCAAoC;IACpC,iCAAiC;IACjC,kBAAkB;AACtB;;AAEA;;IAEI,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,cAAc;IACd,cAAc;AAClB","sourcesContent":[".course-cover{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-evenly;\n    width:90%;\n    aspect-ratio: 1.5 / 1;\n    background-color: var(--subtle-blue);\n    border-radius: 15px;\n}\n\n.course-cover__img{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.course-cover__img img{\n    width:30%;\n    height:auto;\n}\n.course-cover__title{\n    text-align: center;\n}\n.course-cover__title span\n{\n    font-size:18px;\n\n}\n.course-cover__progress-bar{\n    display: flex;\n    position: relative;\n    justify-content: center;\n    align-items: center;\n    width:75%;\n}\n.course-cover__progress-bar progress[value]{\n    appearance: none;\n    width:100%;\n    height:15px;\n}\n.course-cover__progress-bar progress[value]::-webkit-progress-bar\n{\n    background-color: var(--subtle-blue);\n    border:1px solid rgba(0,0,0,0.05);\n    border-radius: 5px;\n}\n\n.course-cover__progress-bar progress[value]::-webkit-progress-value\n{\n    background-color: rgba(102, 126, 234, 0.5);\n    border-radius: 5px;\n}\n\n.course-cover__progress-bar span{\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    margin: 0 auto;\n    font-size:12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
