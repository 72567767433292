import './CourseView.css'
import {useParams} from  "react-router-dom"
import {useEffect, useRef, useState} from "react"
import axios from "axios";
import CourseContent from "../CourseContent/CourseContent";
import CourseTableOfContent from "../CourseTableOfContent/CourseTableOfContent";
import {tableOfContentIcons} from "../../assets/icons";
function CourseView() {
    const {courseId} = useParams();
    const tableOfContentVisibilitySwitchButton = useRef();
    const [courseContent, setCourseContent] = useState("");
    const [courseChapter, setCourseChapter] = useState(1);
    const [tableOfContentVisible, setTableOfContentVisible] = useState(true);
    const fetchAndDisplayCourse =  async (courseId, chapter) => {
        return axios.post(`https://mudlock.tech/courses`, {id:courseId, chapter:chapter}, {
            withCredentials: true
        })
            .then(({data}) => {
                return data;
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const toggleTableOfContentVisibility = () =>
    {
        if(!tableOfContentVisible) setTableOfContentVisible(true)
        else setTableOfContentVisible(false);
        console.log(tableOfContentVisible);
    }

    useEffect(() => {
        (async () => {
            try {
                const content = await fetchAndDisplayCourse(courseId, courseChapter);
                setCourseContent(content);
            } catch (err) {

            }
        })();
    }, [courseChapter]);
    return (

           <div className={`course-view-container ${tableOfContentVisible}`}>
               <CourseTableOfContent visible={tableOfContentVisible} courseId={courseId} setCourseChapter={setCourseChapter}/>
               <CourseContent expanded={tableOfContentVisible} content={courseContent} />
               <button className={`course-view-container__btn ${tableOfContentVisible?"course-view-container__btn--opened":"course-view-container__btn--closed"}`} onClick={toggleTableOfContentVisibility}></button>
           </div>

    )
}

export default CourseView;